var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-sidebar', {
    attrs: {
      "width": "680px",
      "backdrop": "",
      "сlass": "user-sidebar",
      "header-class": "bg-white no-border",
      "body-class": "bg-white",
      "footer-class": "bg-white",
      "right": ""
    },
    on: {
      "hidden": _vm.hideSidebar
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "header-title"
        }, [_vm._v("Добавить пользователя")]), _c('div', {
          staticClass: "header-left-block"
        }, [_c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor mr-3",
          attrs: {
            "variant": "primary",
            "disabled": _vm.canGoNext
          },
          on: {
            "click": _vm.next
          }
        }, [_vm._v(" " + _vm._s(_vm.step === 3 ? 'Сохранить' : 'Продолжить') + " ")]), _c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.back
          }
        }, [_vm._v(" Назад ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [[_c('div', {
    staticClass: "user-sidebar__content"
  }, [_vm.step === 1 ? _c('add-user-form-one', {
    attrs: {
      "user": _vm.user
    }
  }) : _vm.step === 2 ? _c('add-user-form-two', {
    attrs: {
      "user": _vm.user
    }
  }) : _vm.step === 3 ? _c('add-user-form-three', {
    attrs: {
      "user": _vm.user
    }
  }) : _vm._e()], 1)]], 2), _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить пользователя ")])], 1), _c('b-card', {
    staticClass: "users-card p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('users-table', {
    attrs: {
      "users": _vm.users
    },
    on: {
      "user-select": _vm.editUser
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }