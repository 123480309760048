<template>
  <b-form
    class="new-user-form d-flex flex-column justify-content-stretch"
    @submit.prevent="next"
  >
    <div class="d-flex user-from-row">
      <div
        class="mr-3"
        style="flex: 1"
      >
        <label>Email<span class="color-red">*</span></label>
        <b-form-input
          required
          placeholder="Введетие email"
          type="email"
          @input="user.setEmail"
        />
        <span
          v-if="emailValidate"
          class="error"
          >Укажите корректный email</span
        >
      </div>
      <div style="flex: 1">
        <label>Телефон<span class="color-red"></span></label>
        <b-form-input
          placeholder="+7"
          style="flex: 1"
          @input="user.setPhone"
        />
      </div>
    </div>

    <div class="mb-4">
      <div>
        <label>Пароль для Энтерсайта<span class="color-red">*</span></label>
        <password-input
          :placeholder="'Введите пароль'"
          @input="user.setPassword"
        />
      </div>
    </div>

    <div v-if="user.roles.some((el) => el.name === 'Кассир')">
      <div>
        <label>Пароль для кассы<span class="color-red">*</span></label>
        <b-form-input
          type="number"
          :placeholder="'Введите пароль'"
          @input="user.setFrontolPass"
        />
      </div>
      <div class="input-block-text-description">Допустимы только цифры от 3 символов</div>
    </div>
  </b-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { UserModel } from '@/models/user.model'
  import PasswordInput from '@/views/shared/components/elements/PasswordInput'

  export default {
    name: 'AddUserFormThree',
    components: { PasswordInput },
    apollo: {
      Roles: {
        query: require('../gql/Roles.graphql'),
        result({ data }) {
          this.roles = data?.Roles || []
        }
      }
    },
    computed: {
      ...mapGetters({
        branch_list: 'settings/getBranchList'
      }),
      emailValidate() {
        return !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(this.user?.email) || !this.user?.email
      }
    },
    props: {
      user: UserModel
    },
    data() {
      return {
        roles: []
      }
    },
    methods: {
      next() {},
      input_branch(value) {
        this.user.setBranches(value.map((el) => this.branch_list.find((branch) => branch.id === el)))
      },
      input_roles(value) {
        this.user.setRoles(value.map((el) => this.roles.find((roles) => roles.id === el)))
      }
    }
  }
</script>

<style scoped>
  .input-block-text-description {
    color: var(--text-secondary-gray-400, #888);
    margin-top: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0px;
  }
</style>
