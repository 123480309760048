<template>
  <div class="table-tag">
    <div
      v-for="branch in items.slice(0, 1)"
      :key="branch.id"
      class="row-tag"
    >
      {{ branch.name }}
    </div>
    <b-dropdown
      size="lg"
      variant="none"
      class="my-class"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <div
          v-if="items.length > 1"
          class="row-tag-btn"
        >
          {{ items.length - 1 }}
          <img
            src="/img/icons/btn-plus-dark.svg"
            style="height: 8px; margin-left: 4px"
          />
        </div>
      </template>
      <b-dropdown-item
        v-for="branch in items"
        :key="branch.id"
        disabled
        href="#"
      >
        {{ branch.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  export default {
    name: 'TableTagsMore',
    props: {
      items: Array
    }
  }
</script>

<style scoped lang="scss">
  .table-tag {
    display: flex;
    column-gap: 12px;
  }

  .row-tag-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 12px;
    background: #efefef;
    border-radius: 3px;
    color: #313131 !important;
  }

  :deep(.my-class) .dropdown-menu {
    max-height: 355px;
    overflow-y: auto;
    li {
      cursor: default !important;
      &:hover {
        a {
          background: none !important;
          cursor: default !important;
        }
      }
    }
  }
  .row-tag {
    align-items: center;
    display: flex;
  }
</style>
