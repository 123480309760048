var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    staticClass: "new-user-form d-flex flex-column justify-content-stretch",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.next.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', [_c('label', [_vm._v("Роль"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.roles,
      "multiple": "",
      "max_selected_count": 4,
      "placeholder": "Роли",
      "select_name": "roles_select"
    },
    on: {
      "input": _vm.input_roles
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }]),
    model: {
      value: _vm.selectedRoles,
      callback: function ($$v) {
        _vm.selectedRoles = $$v;
      },
      expression: "selectedRoles"
    }
  })], 1)])]), _c('div', {
    staticClass: "mt-4"
  }, [_c('div', [_c('label', [_vm._v("Магазин"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.branch_list,
      "multiple": "",
      "max_selected_count": 4,
      "placeholder": "Магазины",
      "select_name": "roles_select"
    },
    on: {
      "input": _vm.input_branch
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }]),
    model: {
      value: _vm.selectedBraches,
      callback: function ($$v) {
        _vm.selectedBraches = $$v;
      },
      expression: "selectedBraches"
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }