var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fragment', [_c('div', {
    staticClass: "password-wrapper",
    class: _vm.input_class
  }, [_c('b-form-input', _vm._g(_vm._b({
    class: {
      'password-wrapper__error-input': _vm.error
    },
    attrs: {
      "type": _vm.isVisible ? 'text' : 'password',
      "placeholder": _vm.placeholder
    }
  }, 'b-form-input', _vm.$attrs, false), _vm.$listeners)), _c('div', {
    staticClass: "password-wrapper__toggle-view",
    class: {
      'password-wrapper__toggle-view-error-text': _vm.error
    },
    on: {
      "click": _vm.toggleView
    }
  }, [_c('img', {
    attrs: {
      "src": `/img/icons/password_${_vm.isVisible ? 'off' : 'on'}.svg`,
      "alt": "Показать/скрыть пароль"
    }
  })]), _vm.error ? _c('div', {
    class: {
      'password-wrapper__err-text': _vm.error
    }
  }, [_vm._v(" " + _vm._s(_vm.error_text) + " ")]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }