var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-tag"
  }, [_vm._l(_vm.items.slice(0, 1), function (branch) {
    return _c('div', {
      key: branch.id,
      staticClass: "row-tag"
    }, [_vm._v(" " + _vm._s(branch.name) + " ")]);
  }), _c('b-dropdown', {
    staticClass: "my-class",
    attrs: {
      "size": "lg",
      "variant": "none",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.items.length > 1 ? _c('div', {
          staticClass: "row-tag-btn"
        }, [_vm._v(" " + _vm._s(_vm.items.length - 1) + " "), _c('img', {
          staticStyle: {
            "height": "8px",
            "margin-left": "4px"
          },
          attrs: {
            "src": "/img/icons/btn-plus-dark.svg"
          }
        })]) : _vm._e()];
      },
      proxy: true
    }])
  }, _vm._l(_vm.items, function (branch) {
    return _c('b-dropdown-item', {
      key: branch.id,
      attrs: {
        "disabled": "",
        "href": "#"
      }
    }, [_vm._v(" " + _vm._s(branch.name) + " ")]);
  }), 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }