<template>
  <b-table
    :items="users"
    :fields="fields"
    responsive
    tbody-tr-class="cursor"
    @row-clicked="click"
  >
    <template #head(id)>
      <b-checkbox />
    </template>
    <template #cell(id)="data">
      <b-checkbox :value="data" />
    </template>
    <template #cell(name)="data">
      {{ data.item.first_name }}
      {{ data.item.second_name }}
      {{ data.item.last_name }}
    </template>
    <template #cell(roles)="data">
      <table-tags-more :items="data.value || []" />
    </template>
    <template #cell(branches)="data">
      <table-tags-more :items="data.value || []" />
    </template>
  </b-table>
</template>

<script>
  import TableTagsMore from '@/views/settings/components/TableTagsMore.vue'

  export default {
    name: 'UsersTable',
    components: { TableTagsMore },
    props: {
      users: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        fields: [
          { key: 'id', label: '', thStyle: 'width: 15px' },
          { key: 'name', label: 'ФИО' },
          { key: 'roles', label: 'Роль' },
          { key: 'branches', label: 'Магазин' }
        ]
      }
    },
    methods: {
      click(row) {
        this.$emit('user-select', row)
      }
    }
  }
</script>

<style scoped></style>
