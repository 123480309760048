<template>
  <fragment>
    <div
      class="password-wrapper"
      :class="input_class"
    >
      <b-form-input
        :class="{
          'password-wrapper__error-input': error
        }"
        :type="isVisible ? 'text' : 'password'"
        v-bind="$attrs"
        :placeholder="placeholder"
        v-on="$listeners"
      />
      <div
        class="password-wrapper__toggle-view"
        :class="{
          'password-wrapper__toggle-view-error-text': error
        }"
        @click="toggleView"
      >
        <img
          :src="`/img/icons/password_${isVisible ? 'off' : 'on'}.svg`"
          alt="Показать/скрыть пароль"
        />
      </div>
      <div
        v-if="error"
        :class="{
          'password-wrapper__err-text': error
        }"
      >
        {{ error_text }}
      </div>
    </div>
  </fragment>
</template>

<script>
  export default {
    props: {
      input_class: {
        type: String,
        default: () => ''
      },
      error: {
        type: Boolean,
        default: false
      },
      error_text: {
        type: String,
        default: () => 'Неверный пароль'
      },
      placeholder: {
        type: String,
        default: () => ''
      }
    },
    data: () => ({
      isVisible: false
    }),
    methods: {
      toggleView() {
        this.isVisible = !this.isVisible
      }
    }
  }
</script>

<style lang="scss" scoped>
  .password-wrapper {
    position: relative;

    &__toggle-view {
      background: none;
      border: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
    &__toggle-view-error-text {
      background: none;
      border: 0;
      padding: 0;
      position: absolute;
      top: 29%;
      right: 12px;
      transform: translateY(-50%);
    }

    &__error-input {
      border: 1px solid var(--text-alert-red-400, #e53835);
    }

    &__err-text {
      color: var(--text-alert-red-400, #e53835);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 4px;
    }
  }

  ::v-deep() {
    .password-wrapper input {
      padding-right: 38px;
    }
  }

  // .toggle-view {
  //   background: none;
  //   border: 0;
  //   padding: 0;
  //   position: absolute;
  //   top: 50%;
  //   right: 12px;
  //   transform: translateY(-50%);
  // }
</style>
