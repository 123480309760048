<template>
  <b-form
    class="new-user-form d-flex flex-column justify-content-stretch"
    @submit.prevent="next"
  >
    <div>
      <div>
        <label>Роль<span class="color-red">*</span></label>
        <div style="width: 100%">
          <e-select
            v-model="selectedRoles"
            class="e-select-el"
            :options="roles"
            multiple
            :max_selected_count="4"
            placeholder="Роли"
            select_name="roles_select"
            @input="input_roles"
          >
            <template #custom_name="{ item }">
              <div>
                <p class="e-select-el__option-name">{{ item.name }}</p>
              </div>
            </template>
          </e-select>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div>
        <label>Магазин<span class="color-red">*</span></label>
        <div style="width: 100%">
          <e-select
            v-model="selectedBraches"
            class="e-select-el"
            :options="branch_list"
            multiple
            :max_selected_count="4"
            placeholder="Магазины"
            select_name="roles_select"
            @input="input_branch"
          >
            <template #custom_name="{ item }">
              <div>
                <p class="e-select-el__option-name">{{ item.name }}</p>
              </div>
            </template>
          </e-select>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { UserModel } from '@/models/user.model'

  export default {
    name: 'AddUserFormTwo',
    apollo: {
      Roles: {
        query: require('../gql/Roles.graphql'),
        result({ data }) {
          this.roles = data?.Roles || []
        }
      }
    },

    props: {
      user: UserModel
    },
    data() {
      return {
        roles: [],
        selectedRoles: [],
        selectedBraches: []
      }
    },

    computed: {
      ...mapGetters({
        branch_list: 'settings/getBranchList'
      })
    },

    methods: {
      next() {},
      input_branch(value) {
        this.selectedBraches = value
        this.user.setBranches(value)
      },
      input_roles(value) {
        this.user.setRoles(value)
      }
    }
  }
</script>

<style scoped></style>
