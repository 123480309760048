var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table', {
    attrs: {
      "items": _vm.users,
      "fields": _vm.fields,
      "responsive": "",
      "tbody-tr-class": "cursor"
    },
    on: {
      "row-clicked": _vm.click
    },
    scopedSlots: _vm._u([{
      key: "head(id)",
      fn: function () {
        return [_c('b-checkbox')];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('b-checkbox', {
          attrs: {
            "value": data
          }
        })];
      }
    }, {
      key: "cell(name)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.first_name) + " " + _vm._s(data.item.second_name) + " " + _vm._s(data.item.last_name) + " ")];
      }
    }, {
      key: "cell(roles)",
      fn: function (data) {
        return [_c('table-tags-more', {
          attrs: {
            "items": data.value || []
          }
        })];
      }
    }, {
      key: "cell(branches)",
      fn: function (data) {
        return [_c('table-tags-more', {
          attrs: {
            "items": data.value || []
          }
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }