var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    staticClass: "new-user-form d-flex flex-column justify-content-stretch",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.next.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex user-from-row"
  }, [_c('div', {
    staticClass: "mr-3",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('label', [_vm._v("Email"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    attrs: {
      "required": "",
      "placeholder": "Введетие email",
      "type": "email"
    },
    on: {
      "input": _vm.user.setEmail
    }
  }), _vm.emailValidate ? _c('span', {
    staticClass: "error"
  }, [_vm._v("Укажите корректный email")]) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }, [_c('label', [_vm._v("Телефон"), _c('span', {
    staticClass: "color-red"
  })]), _c('b-form-input', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "placeholder": "+7"
    },
    on: {
      "input": _vm.user.setPhone
    }
  })], 1)]), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', [_c('label', [_vm._v("Пароль для Энтерсайта"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('password-input', {
    attrs: {
      "placeholder": 'Введите пароль'
    },
    on: {
      "input": _vm.user.setPassword
    }
  })], 1)]), _vm.user.roles.some(function (el) {
    return el.name === 'Кассир';
  }) ? _c('div', [_c('div', [_c('label', [_vm._v("Пароль для кассы"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": 'Введите пароль'
    },
    on: {
      "input": _vm.user.setFrontolPass
    }
  })], 1), _c('div', {
    staticClass: "input-block-text-description"
  }, [_vm._v("Допустимы только цифры от 3 символов")])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }