<template>
  <div>
    <b-sidebar
      v-model="show"
      width="680px"
      backdrop
      сlass="user-sidebar"
      header-class="bg-white no-border"
      body-class="bg-white"
      footer-class="bg-white"
      right
      @hidden="hideSidebar"
    >
      <template #header="{ hide }">
        <div class="header-title">Добавить пользователя</div>
        <div class="header-left-block">
          <img
            class="close-sidebar cursor"
            src="/img/integrations/icon-sidebar-close.svg"
            alt=""
            @click="hide"
          />
        </div>
      </template>

      <template>
        <div class="user-sidebar__content">
          <add-user-form-one
            v-if="step === 1"
            :user="user"
          />
          <add-user-form-two
            v-else-if="step === 2"
            :user="user"
          />
          <add-user-form-three
            v-else-if="step === 3"
            :user="user"
          />
        </div>
      </template>

      <template #footer>
        <div class="b-action-buttons">
          <b-button
            variant="primary"
            class="cursor mr-3"
            :disabled="canGoNext"
            @click="next"
          >
            {{ step === 3 ? 'Сохранить' : 'Продолжить' }}
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor"
            @click="back"
          >
            Назад
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <!-- <div class="content__header">{{ title }}</div> -->
    <div>
      <div class="d-flex">
        <b-button
          class="ml-auto"
          variant="primary"
          @click="show = !show"
        >
          <img
            src="/img/icons/btn-plus.svg"
            class="mr"
            alt=""
          />
          Добавить пользователя
        </b-button>
      </div>
      <b-card
        no-body
        class="users-card p-0"
      >
        <users-table
          :users="users"
          @user-select="editUser"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
  import AddUserFormOne from '@/views/settings/components/AddUserFormOne.vue'
  import UsersTable from '@/views/settings/components/UsersTable.vue'
  import AddUserFormTwo from '@/views/settings/components/AddUserFormTwo.vue'
  import { UserModel } from '@/models/user.model'
  import AddUserFormThree from '@/views/settings/components/AddUserFormThree.vue'
  export default {
    name: 'Users',
    components: {
      AddUserFormThree,
      AddUserFormTwo,
      UsersTable,
      AddUserFormOne
    },
    apollo: {
      UserFromCompany: {
        query: require('../gql/GetUsers.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.users = data?.UserFromCompany
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },
    data() {
      return {
        show: false,
        title: this.$route.meta.title,

        users: [],
        step: 1,
        user: new UserModel()
      }
    },
    computed: {
      canGoNext() {
        return this.user['validateUser' + this.step]
      }
    },
    methods: {
      hideSidebar() {
        this.step = 1
        this.user = new UserModel()
      },

      async next() {
        if (this.step === 3) {
          try {
            await this.$apollo.mutate({
              mutation: require('../gql/CreateUser.graphql'),
              variables: this.user.input
            })
            this.$noty.show(`Успешно!`)
            this.$apollo.queries.UserFromCompany.refresh()
            this.user = new UserModel()
            return (this.show = false)
          } catch (err) {
            this.$noty.show(`Что-то пошло не так!`)
            console.log(err)
          }
        }
        this.step += 1
      },

      back() {
        if (this.step === 1) return (this.show = false)
        this.step -= 1
      },
      async editUser(user) {
        await this.$router.push({ name: 'user.edit', params: { id: user.id } })
      }
    }
  }
</script>

<style scoped lang="scss">
  .users-card {
    margin-top: 16px;
  }

  ::v-deep() {
    .b-sidebar-header {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 85px;
      margin-bottom: 20px;
      background-color: white;

      .header-left-block {
        margin-left: auto;
        &__button {
          color: var(--text-primary-gray-700, #313131);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 2px;
          background: var(--gray-gray-50, #efefef);
          margin-right: 16px;
        }
      }
    }

    .text-dark {
      background-color: white !important;
    }

    .user-sidebar {
      &__content {
        padding-left: 22px;
        padding-right: 22px;
      }
    }

    .new-user-form input {
      height: 42px !important;
    }

    .new-user-form label {
      margin-bottom: 6px !important;
    }
    .user-from-row {
      margin-bottom: 22px;
      flex: 1;
    }
    .b-sidebar-footer {
      position: relative;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      height: 65px;
      display: flex;
      align-items: center;
      padding-left: 22px;
      padding-right: 22px;
    }
  }
</style>
