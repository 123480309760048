var render = function render(){
  var _vm$user$first_name, _vm$user$last_name;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    staticClass: "new-user-form d-flex flex-column justify-content-stretch",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.next.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', [_vm._v("Имя"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    attrs: {
      "required": "",
      "value": _vm.user.first_name,
      "placeholder": "Введите имя",
      "type": "text"
    },
    on: {
      "input": _vm.user.setFirstName
    }
  }), _vm.user.first_name && ((_vm$user$first_name = _vm.user.first_name) === null || _vm$user$first_name === void 0 ? void 0 : _vm$user$first_name.length) < 2 ? _c('span', {
    staticClass: "error"
  }, [_vm._v("Укажите корректное имя")]) : _vm._e()], 1), _c('div', {
    staticClass: "mb-4"
  }, [_c('label', [_vm._v("Фамилия"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    attrs: {
      "value": _vm.user.last_name,
      "placeholder": "Введите фамилию"
    },
    on: {
      "input": _vm.user.setLastName
    }
  }), _vm.user.last_name && ((_vm$user$last_name = _vm.user.last_name) === null || _vm$user$last_name === void 0 ? void 0 : _vm$user$last_name.length) < 2 ? _c('span', {
    staticClass: "error"
  }, [_vm._v("Укажите корректную фамилию")]) : _vm._e()], 1)]), _c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('label', [_vm._v("Отчество")]), _c('b-form-input', {
    attrs: {
      "value": _vm.user.second_name,
      "placeholder": "Введите отчество",
      "type": "text"
    },
    on: {
      "input": _vm.user.setSecondName
    }
  })], 1), _c('div')]), _c('div', {
    staticClass: "d-flex user-from-row"
  }, [_c('div', {
    staticClass: "mr-3",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('label', [_vm._v("Комментарий")]), _c('b-form-textarea', {
    attrs: {
      "type": "text",
      "rows": "5"
    }
  })], 1), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }