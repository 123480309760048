<template>
  <b-form
    class="new-user-form d-flex flex-column justify-content-stretch"
    @submit.prevent="next"
  >
    <div>
      <div class="mb-4">
        <label>Имя<span class="color-red">*</span></label>
        <b-form-input
          required
          :value="user.first_name"
          placeholder="Введите имя"
          type="text"
          @input="user.setFirstName"
        />
        <span
          v-if="user.first_name && user.first_name?.length < 2"
          class="error"
          >Укажите корректное имя</span
        >
      </div>
      <div class="mb-4">
        <label>Фамилия<span class="color-red">*</span></label>
        <b-form-input
          :value="user.last_name"
          placeholder="Введите фамилию"
          @input="user.setLastName"
        />
        <span
          v-if="user.last_name && user.last_name?.length < 2"
          class="error"
          >Укажите корректную фамилию</span
        >
      </div>
    </div>
    <div>
      <div
      class="mb-4"
      >
        <label>Отчество</label>
        <b-form-input
          :value="user.second_name"
          placeholder="Введите отчество"
          type="text"
          @input="user.setSecondName"
        />
      </div>
      <div>
        <!--        <label>ИНН</label>-->
        <!--        <b-form-input-->
        <!--          type="number"-->
        <!--          placeholder="Введите ИНН. 10 или 12 цифр"-->
        <!--          style="flex: 1"-->
        <!--        />-->
      </div>
    </div>
    <div class="d-flex user-from-row">
      <div
        style="flex: 1"
        class="mr-3"
      >
        <label>Комментарий</label>
        <b-form-textarea
          type="text"
          rows="5"
        />
      </div>
      <div style="flex: 1"></div>
    </div>
  </b-form>
</template>

<script>
  import { UserModel } from '@/models/user.model'

  export default {
    name: 'AddUserFormOne',
    props: {
      user: UserModel
    },
    methods: {
      next() {
        this.$emit('to_step', 2)
      }
    }
  }
</script>

<style scoped></style>
